<template>
    <div class="userManage page-container">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="btnBox">
            <div class="btn">
                <el-button size="small" type="primary" @click="createUser()">新建用户</el-button>
                <el-dropdown>
                    <el-button size="small">批量操作 <i class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
<!--                        <el-dropdown-item @click.native="delUser()">删除用户</el-dropdown-item>-->
                        <el-dropdown-item @click.native="operate('用户停用后将无法', '停用', 'DISABLE')">停用用户</el-dropdown-item>
                        <el-dropdown-item @click.native="operate('用户恢复后可以继续', '恢复', 'NORMAL')">停用恢复</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button size="small" @click="refresh()">刷新</el-button>
            </div>
            <el-input placeholder="请输入用户账号" v-model="searchText" clearable @keyup.enter.native="search()"
                      class="searchInput">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="search()"></i>
            </el-input>
        </div>
        <div class="tableBox">
            <el-table
                :data="tableData"
                @selection-change="handleSelectionChange"
                :span-method="handleTableSpan"
                :row-key="row => row.bid"

                v-loading="loading" ref="table">
                <el-table-column
                    :selectable="(row) => {
                        return row.regionIdList &&row.regionIdList.indexOf(regionId) > -1
                    }"
                    type="selection" :reserve-selection="true" width="45" align="center">
                </el-table-column>
                <el-table-column prop="no" label="序号" width="55" align="center">
                    <template slot-scope="scop">
                        {{ (pager.current - 1) * 10 + scop.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="用户" max-width="200" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="email" @click="lookInfo(scope.row.bid)">{{ scope.row.username }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="regionName" label="资源池" max-width="300" align="center"
                                 show-overflow-tooltip>

                    <template slot-scope="scope">
                        <div v-if="scope.row.regionIdList && scope.row.regionIdList.indexOf(regionId) === -1"
                             class="row-span">未开通资源池
                        </div>
                        <div v-else>{{ scope.row.regionName }}</div>
                    </template>

                </el-table-column>
                <el-table-column label="用户配额" align="center" width="300">
                    <template slot-scope="scope">
                        <div class="quota">
                            <span>{{ formatBytes(scope.row.usedSpace) }}</span>
                            <div class="progress">
                                <div class="proportion"
                                     :style="{width: percentage(scope.row.usedSpace,scope.row.quota)}"></div>
                            </div>
                            <span>{{ scope.row.quota == 0 ? "无限制" : formatBytes(scope.row.quota) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createdAt" label="创建时间" align="center" width="160">
                    <template slot-scope="scope">
                        {{ scope.row.createdAt ? moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-' }}
                    </template>
                </el-table-column>
                <el-table-column label="账号状态" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag effect="dark" size="small"
                                v-if="scope.row.status === 'NORMAL'"
                                type="success">启用
                        </el-tag>
                        <el-tag effect="dark" size="small" v-else type="danger">停用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column v-if="glacierType == 0" show-overflow-tooltip prop="placementId" label="存储策略"
                                 align="center" width="160">
          <span slot-scope="scope">
            {{
                  scope.row.placementId == '' || scope.row.placementId == null ? 'default-placement' : scope.row.placementId
              }}
          </span>
                </el-table-column>
                <el-table-column prop="placementId" label="存储规则" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="100">
                    <template slot-scope="scope">

                        <div class="file-table-action-list"
                             v-if="scope.row.regionIdList && scope.row.regionIdList.indexOf(regionId) === -1">
                            <el-button type="text" @click="lookInfo(scope.row.bid)">开通</el-button>

                        </div>
                        <div class="file-table-action-list" v-else>
                            <el-button type="text" @click="delUser(scope.row)">删除</el-button>

                            <el-button type="text" @click="operate('用户停用后将无法', '停用', 'DISABLE', scope.row)"
                                       v-if="scope.row.status === 'NORMAL'">停用
                            </el-button>
                            <el-button type="text" @click="operate('用户恢复后可以继续', '恢复', 'NORMAL', scope.row)" v-else>恢复
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20, 50, 100]"
                   @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>

        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import {formatBytes, percentage} from '../../utils/utils2'
import moment from 'moment'
import storage from "@/utils/storage";


export default {
    name: 'userManage',
    data() {
        return {
            status: '4',
            searchText: '',
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
            tableData: [],
            selectedData: [],
            isRefresh: false,
            loading: false,
            regionId: ''
        }
    },
    computed: {
        ...mapGetters(['appName'])
        // ...mapState('userManage', ['loading']),
        // ...mapState('app', ['pageSize']),
        // ...mapState('user', ['glacierType']),
    },
    created() {
        this.getList()
        this.regionId = storage.get('REGION_ID')
    },
    methods: {
        // ...mapActions('userManage', ['getUser', 'deleteUser', 'operateUser']),
        moment,
        //  批量处理用户
        handleAccounts(data, successInfo = '操作成功') {
            this.$postJson('/admin/batchModifyAccountUser', data)
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success(successInfo)
                    }
                })
                .finally((res) => {
                    this.pager.current = 1
                    this.$refs.table.clearSelection()
                    this.getList()
                })
        },
        // 处理表格合并
        handleTableSpan({row, column, rowIndex, columnIndex}) {
            if (row.regionIdList && row.regionIdList.indexOf(this.regionId) === -1) {
                if (columnIndex === 3) {
                    return [1, 5]
                } else if (columnIndex > 3 && columnIndex < 8) {
                    return [0, 0]
                }
            }
        },



        getList() {
            this.$postJson(`/admin/pageAccountUser`, {
                pageNum: this.pager.current,
                pageSize: this.pager.size,
                username: this.searchText,

            })
                .then((res) => {
                    if (res.code === '200') {
                        this.pager.total = res.data.total
                        this.tableData = res.data.records
                    }

                })
                .finally(() => {
                    this.isRefresh = false
                })
        },
        formatBytes(val) {
            return formatBytes(val)
        },
        percentage(use, all) {
            return percentage(use, all)
        },
        search() {
            this.pager.current = 1
            this.getList()
        },
        handleSelectionChange(val) {
            this.selectedData = val
        },
        handleCurrentChange(val) {
            this.pager.current = val
            //  this.$refs.table.clearSelection();
            this.getList()
        },

        handleSizeChange(size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.getList();
        },

        lookInfo(val) {
            this.$router.push('/userInfo?id=' + val)
        },
        createUser() {
            this.$router.push({name: 'createUser'})
        },
        delUser(single) {
            if (this.selectedData.length == 0 && !single) {
                this.$message.error('请至少选择一位用户')
                return
            }
            this.$confirm('删除后用户将无法登录控制台，且无法接收短信邮件等通知。', '删除用户', {
                type: 'warning',
            }).then(() => {
                let selectedData = single ? [single.bid] : this.selectedData.map((item) => item.bid)
                this.handleAccounts({accountUserIdList: selectedData, status: 'DELETE'})
            })
        },
        operate(tips, title, status, singleUser) {
            if (this.selectedData.length == 0 && !singleUser) {
                this.$message.error('请至少选择一位用户')
                return
            }

            let selectedData = singleUser ? [singleUser] : this.selectedData

            // DISABLE NORMAL
            /** 当前所选都是停用状态， 不需要继续停用操作  **/
            if (status === 'DISABLE' && selectedData.filter(i => i.status !== 'DISABLE').length === 0) {
                this.$message.warning('所选用户已经停用')
                return;
            }
            /** 当前所选都是正常状态， 不需要继续恢复操作  **/
            if (status === 'NORMAL' && selectedData.filter(i => i.status !== 'NORMAL').length === 0) {
                this.$message.warning('所选用户未停用')
                return;
            }

            this.$confirm(`${tips}使用${this.appName}控制台`, `${title}用户`, {
                type: 'warning',
            }).then(() => {
                this.handleAccounts({accountUserIdList: selectedData.map((item) => item.bid), status})
            })
        },
        refresh() {
            this.isRefresh = true
            this.getList()
        },
    },
}
</script>
<style lang="scss" scoped>
.userManage {
    /deep/ {
        .el-dropdown {
            margin-left: 10px;

            & + .el-button {
                margin-left: 10px;
            }
        }
    }
}

.btnBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .searchInput {
        width: 240px;

        .el-icon-search {
            cursor: pointer;
        }
    }
}

.email {
    color: #006bff;
    cursor: pointer;
}

.row-span {
    width: 100%;
    margin: 0;
    text-align: center;
}

.quota {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        width: 75px;
        display: inline-block;
    }

    .progress {
        width: 65%;
        height: 7px;
        background: #f5f5f5;
        border-radius: 4px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        position: relative;
    }

    .proportion {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 20%;
        background: #006bff;
    }
}

.tableBox {
    .status {
        cursor: pointer;
    }

    .title {
        color: #006bff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }
}
</style>
